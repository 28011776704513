import { Button } from "@mui/material";
import React, {useRef} from "react";
import '../../Styles/Landing.css'
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   
 

const Header = ({
    contactRef
}) => {

    const executeScroll = () => scrollToRef(contactRef)

    return(
        <div className="header">
            <img src="logo.svg" className="logo"/>
            <Button onClick={executeScroll} variant="outlined" className="button-with-outline">Contact Us</Button>
        </div>
    )
}

export default Header;