import { Button , Grid } from "@mui/material";
import React from "react";
import '../../Styles/Landing.css'

const ProjectCard = ({

    image,
    projectName,
    tagline,
    content,
    profileImg,
    signature,
    designation

}) => {

    return(
        <Grid container className="project-card">
            <Grid item sm={12} md={6} className="text-side">
                <Grid container>
                    <Grid item >
                        <img className="profile-img" src={profileImg}/>
                    </Grid>
                    <Grid item>
                        <span className="project-name">{projectName}</span>
                        <div className="project-subtitle"><i>{tagline}</i></div>
                    </Grid>
                </Grid>
                <p>{content}</p>

                <div className="signature-name">{signature}</div>
                <div className="signature-designation">{designation}</div>
            </Grid>
            <Grid item sm={12} md={6} className="image-side">
                <img
                    src={image}
                />    
            </Grid>
        </Grid>
    )
}

export default ProjectCard;