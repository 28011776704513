import { Grid } from "@mui/material";
import React from "react";
import '../../Styles/Landing.css'
const ImageText_TwoSideScreen = ({
    imageSide,
    image,
    heading,
    content,
    backgroundHeading
}) => {
    return(
        imageSide=="right" || window.screen.width<=992?
        <Grid container className="two-side-screen">
           <Grid item sm={12} md={6} className="text-side">
                <h1>{backgroundHeading}</h1>
                <h2>{heading}</h2>
                <p>{content}</p>
           </Grid>
           <Grid item sm={12} md={6} className="image-side">
                <img src={image}/>    
           </Grid>
        </Grid>
       :
        <Grid container className="two-side-screen">
            <Grid item sm={12} md={6} className="image-side">
                <img src={image}/>
            </Grid>
            <Grid item sm={12} md={6} className="text-side left">
                <h1>{backgroundHeading}</h1>
                <h2>{heading}</h2>
                <p>{content}</p>
            </Grid>
        </Grid>
        )
}

export default ImageText_TwoSideScreen;