import React, { useRef } from 'react';
import './App.css';
import Grid from '@mui/material/Grid';
import Header from './Components/elements/Header';
import { Button, TextField, Stack } from '@mui/material';
import ImageText_TwoSideScreen from './Components/elements/ImageText_TwoSideScreen';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ProjectCard from './Components/elements/ProjectCard';
import { Carousel } from 'react-responsive-carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { PortfolioData } from './Constants/portfolio';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css';
import { sendMail } from './utils/mail';

const App=()=>{

  const contactRef = useRef(null)
  const heroContactRef = useRef();
	const formNameRef = useRef();
	const formContactRef = useRef();
	const formReqRef = useRef();
	const sendHeroMail = e => {
		const contact = heroContactRef.current.value;
		sendMail({
			contact,
		});
		heroContactRef.current.value = '';
	};
	const sendFormMail = e => {
		const contact = formContactRef.current.value;
		const name = formNameRef.current.value;
		const requirements = formReqRef.current.value;
		console.log(name);
		if (
			!name ||
			name.length < 2 ||
			!contact ||
			contact.length < 4 ||
			!requirements
		) {
			alert('Please fill all fields');
			return;
		}
		sendMail({
			contact,
			name,
			requirements,
		});
		formContactRef.current.value = '';
		formNameRef.current.value = '';
		formReqRef.current.value = '';
	};

  return (
    <div className="landing-page">
        <Header 
          contactRef={contactRef}  
        />
        {
          window.screen.width>=992?
          <Grid  container className="hero-section">
          <Grid item xs={12} sm={12} md={7}  className="cta-side">
            <div className="heading1">We DEVELOP</div>
            <div className="heading2">Your DREAMS!</div>
            <div className="para">
                We help convert your idea to a product in millions of hands            
            </div>
            
            <Grid container className="cta-section" >
              <Grid item>
                <TextField
                  id="outlined-bare"
                  margin="normal"
                  variant="outlined"
                  className="cta"
                  placeholder="Enter your email address/phone"
                  inputRef={heroContactRef}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
              </Grid>
              <Grid>
                <Button Click={sendHeroMail}  className="button">Let's Connect</Button>   
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5} className="image-side">
            <img src="hero.gif"/>
            <img className="img-bg" src="hero-img.svg"></img>
          </Grid>
        </Grid>
        :
        <Grid  container className="hero-section">
          <Grid item xs={12} sm={12} md={5} className="image-side">
            <img src="hero.gif"/>
            <img className="img-bg" src="hero-img.svg"></img>
          </Grid>
          <Grid item xs={12} sm={12} md={7}  className="cta-side">
            <div className="heading1">We DEVELOP</div>
            <div className="heading2">Your DREAMS!</div>
            <div className="para">
                We help convert your idea to a product in millions of hands            
            </div>
            
            <Grid container className="cta-section" >
              <Grid item>
                <TextField
                  id="outlined-bare"
                  margin="normal"
                  variant="outlined"
                  className="cta"
                  placeholder="Enter your email address/phone"
                  inputRef={heroContactRef}
                />
              </Grid>
              <Grid>
                <Button Click={sendHeroMail}  className="button">Let's Connect</Button>   
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
       
        
        <ImageText_TwoSideScreen 
          imageSide="right"
          className="design"
          image="design.svg"
          backgroundHeading="Design"
          heading="Market Research to User Interface"
          content="We help shape your ideas into UI prototypes for quickest iterations using stakeholder feedback "
        />

        <ImageText_TwoSideScreen 
          imageSide="left"
          className="dev"
          image="dev.svg"
          backgroundHeading="Developement"
          heading="User Interface to MVP"
          content="We develop a minimum viable product powered by stakeholder feedback with deep analtytics for product insights"
        />

        <ImageText_TwoSideScreen 
          imageSide="right"
          className="dev"
          image="devops.svg"
          backgroundHeading="DevOps"
          heading="MVP to Product Release"
          content="We deploy and maintain products that can scale through cloud automation drastically reducing costs without compromising on performance "
        />

			<div className='porfolio-section'>
				<div className='section-heading'>Client Feedback</div>
				<hr />

        <Swiper
              spaceBetween={50}
              slidesPerView={1}
              modules={[Navigation, Pagination, A11y, Autoplay]}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
              navigation
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
                {
                  PortfolioData.map((portfolioItem)=>{

                    return(
                      <SwiperSlide>
                        <ProjectCard
                          image = {portfolioItem.image}
                          tagline = {portfolioItem.tagline}
                          content = {portfolioItem.content}
                          projectName = {portfolioItem.projectName}
                          profileImg = {portfolioItem.profileImg}
                          signature = {portfolioItem.signature}
                          designation = {portfolioItem.designation}
                        /> 
                      </SwiperSlide>
                    )

                  })
                }
        </Swiper>
        </div>

			<div className='contact-section' ref={contactRef}>
				<div className='section-heading'>Let's Collaborate</div>
				<hr />

				<div className='contact-textbox'>
					<Stack>
						<Grid container>
							<input
								ref={formNameRef}
								placeholder='Name'
								className='name inputbox'
							/>
							<input
								ref={formContactRef}
								placeholder='Email Address'
								className='email inputbox'
							/>
						</Grid>
						<Grid container>
							<textarea
								ref={formReqRef}
								placeholder='Your requirements'
								className='message textarea'
							/>
						</Grid>
						<Button onClick={sendFormMail} className='button-with-outline'>
							Get In Touch
						</Button>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default App;
