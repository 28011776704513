export const PortfolioData = 

[
    {
        image:"testimonial/port1.png",
        tagline:"Risk Management Tool for Futures Trading",
        content:"I’ve worked with SimpleM over various app mandates in the last two years, and found it to be a phenomenal experience in both ease of communication and delivery of items. They are very good at re-haul of existing apps, ui , as well as developing back end functionalities even those that require lot of math, calculations from scratch. They go out of their way to ensure the product meets our expectations and always ready to edit and re-work if needed. Highly recommend. ",
        projectName:"Risk Management Tool ",
        profileImg:"testimonial/1.png",
        signature:"Nikhil Bandale",
        designation: "VP - Algorithmic Trading | Axxela Research and Analytics Ltd"
    },

    {
        image:"testimonial/port2.png",
        tagline:"Collaborate and create music videos",
        content:"Working with Simplem has been incredible. The team is extremely agile and is able to deploy large features rather quickly. Where Simplem really stands out are in their efforts to understand the requirements down to the minutest of details. They are good at time estimation and stick to timelines. They are reasonable and quick at accommodating scope changes with their upfront rate card. Overall it has been a great experience working with the Simplem team - building and deploying a product that has been used by thousands of users, bringing in revenue to QuickJam.",
        projectName:"QuickJam",
        profileImg:"testimonial/2.png",
        signature:"Lyle Rodricks",
        designation: "CEO and Founder | Telkins Solutions"
    },

    {
        image:"testimonial/port3.png",
        tagline:"Mutliplayer fun game fueled by imagination",
        content:"I loved the use of intellect and fun in the programs selected to make this unique experience. It helps to understand other's perspective. Kudos to the developers!! ",
        projectName:"Felix",
        profileImg:"testimonial/3.png",
        signature:"Hemant Kumar",
        designation: "Manufacturing Executive | Godrej Industries Ltd"
    },

    {
        image:"testimonial/port4.png",
        tagline:"Gamified fusion of Futures Trading and Poker!",
        content:"It was wonderful working with the team SimpleM. They provided timely and quick resolution of every problem we faced. Coming to their team, they have extremely dedicated staff to cater to client needs. We really loved the efforts put in my the team and the final product.",
        projectName:"Axxela Trade Game",
        profileImg:"testimonial/4.png",
        signature:"Amitkumar Pillai",
        designation: "Co-Founder | Tradia Markets, AVP | Axxela"
    }
]